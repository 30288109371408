import {Nav, Navbar} from "react-bootstrap";
import React from "react";
import './NavigationBar.css'
import {LinkContainer} from "react-router-bootstrap";
import logo from '../../examic-logo.svg';
import Badge from "react-bootstrap/Badge";

const NavigationBar = (props) => {

  return (
      <Navbar collapseOnSelect expand="lg" bg="info" variant="dark"
              className={"border border-top-0 border-left-0 border-right-0 shadow-sm"}>
        <Navbar.Brand>
          <img
              src={logo}
              width="40"
              height="40"
              className="d-inline-block align-top"
              alt="Examic logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/">
              <Nav.Link><h2><Badge variant="info">E x a m i c</Badge></h2></Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav>
            {props.isAuthenticated
                ? <>
                  <LinkContainer to="/">
                    <Nav.Link>Performance</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/profile">
                    <Nav.Link>Profile</Nav.Link>
                  </LinkContainer>
                  <Nav.Item onClick={props.handleLogout}>
                    <Nav.Link>Logout</Nav.Link>
                  </Nav.Item>
                </>
                : <>
                  <LinkContainer to="/signup">
                    <Nav.Link>Signup</Nav.Link>
                  </LinkContainer>
                  {" "}
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
};

export default NavigationBar;