import {Card} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import React from "react";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";

const Dashboard = () => {
  return (
      <Row className={"justify-content-md-center p-3"}>
        <Col md="auto">
          <Alert variant={"info"} className={"text-center"}>
            To Do
          </Alert>

          <Card
              bg={'warning'}
              text={'light'}
              style={{width: '18rem'}}
          >
            <Card.Header>Time Table 5 </Card.Header>
            <Card.Body>
              <Card.Title>Card Title </Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up the
                bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <Card.Footer>Attempts: <Badge variant="light">0</Badge></Card.Footer>
          </Card>
          <br/>
          <Card
              bg={'danger'}
              text={'light'}
              style={{width: '18rem'}}
          >
            <Card.Header>Time Table 5 </Card.Header>
            <Card.Body>
              <Card.Title>Card Title </Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up the
                bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <Card.Footer>Attempts: <Badge variant="light">3</Badge></Card.Footer>
          </Card>

        </Col>
        <Col md="auto">
          <Alert variant={"info"} className={"text-center"}>
            In Progress
          </Alert>
          <Card
              bg={'info'}
              text={'light'}
              style={{width: '18rem'}}
          >
            <Card.Header>Time Table 3</Card.Header>
            <Card.Body>
              <Card.Title>Card Title </Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up the
                bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <Card.Footer>Attempts: <Badge variant="light">1</Badge></Card.Footer>
          </Card>
        </Col>
        <Col md="auto">
          <Alert variant={"info"} className={"text-center"}>
            Done
          </Alert>
          <Card
              bg={'success'}
              text={'light'}
              style={{width: '18rem'}}
          >
            <Card.Header>Time Table 2</Card.Header>
            <Card.Body>
              <Card.Title>Card Title </Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up the
                bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <Card.Footer>Attempts: <Badge variant="light">2</Badge></Card.Footer>
          </Card>
        </Col>

        {/*<CardDeck>*/}
        {/*  <Card style={{width: '22rem'}} className="text-left">*/}
        {/*    <Card.Header className="text-center" bg={'dark'} variant={'dark'}>*/}
        {/*      To Do*/}
        {/*    </Card.Header>*/}
        {/*    <Card.Body>*/}

        {/*      <Card*/}
        {/*          bg={'warning'}*/}
        {/*          text={'light'}*/}
        {/*          style={{ width: '18rem' }}*/}
        {/*      >*/}
        {/*        <Card.Header>Time Table 5</Card.Header>*/}
        {/*        <Card.Body>*/}
        {/*          <Card.Title>Card Title </Card.Title>*/}
        {/*          <Card.Text>*/}
        {/*            Some quick example text to build on the card title and make up the*/}
        {/*            bulk of the card's content.*/}
        {/*          </Card.Text>*/}
        {/*        </Card.Body>*/}
        {/*      </Card>*/}
        {/*      <br/>*/}
        {/*      <Card*/}
        {/*          bg={'danger'}*/}
        {/*          text={'light'}*/}
        {/*          style={{ width: '18rem' }}*/}
        {/*      >*/}
        {/*        <Card.Header>Synonyms C* (Retake)</Card.Header>*/}
        {/*        <Card.Body>*/}
        {/*          <Card.Title>Card Title </Card.Title>*/}
        {/*          <Card.Text>*/}
        {/*            Some quick example text to build on the card title and make up the*/}
        {/*            bulk of the card's content.*/}
        {/*          </Card.Text>*/}
        {/*        </Card.Body>*/}
        {/*      </Card>*/}

        {/*    </Card.Body>*/}
        {/*  </Card>*/}
        {/*  <Card style={{width: '22rem'}} className="text-left">*/}
        {/*    <Card.Header className="text-center">*/}
        {/*      In Progress*/}
        {/*    </Card.Header>*/}
        {/*    <Card.Body>*/}
        {/*      <Card*/}
        {/*          bg={'info'}*/}
        {/*          text={'light'}*/}
        {/*          style={{ width: '18rem' }}*/}
        {/*      >*/}
        {/*        <Card.Header>Synonyms B*</Card.Header>*/}
        {/*        <Card.Body>*/}
        {/*          <Card.Title>Card Title </Card.Title>*/}
        {/*          <Card.Text>*/}
        {/*            Some quick example text to build on the card title and make up the*/}
        {/*            bulk of the card's content.*/}
        {/*          </Card.Text>*/}
        {/*        </Card.Body>*/}
        {/*      </Card>*/}
        {/*      <br/>*/}
        {/*      <Card*/}
        {/*          bg={'info'}*/}
        {/*          text={'light'}*/}
        {/*          style={{ width: '18rem' }}*/}
        {/*      >*/}
        {/*        <Card.Header>Synonyms B*</Card.Header>*/}
        {/*        <Card.Body>*/}
        {/*          <Card.Title>Card Title </Card.Title>*/}
        {/*          <Card.Text>*/}
        {/*            Some quick example text to build on the card title and make up the*/}
        {/*            bulk of the card's content.*/}
        {/*          </Card.Text>*/}
        {/*        </Card.Body>*/}
        {/*      </Card>*/}

        {/*    </Card.Body>*/}
        {/*  </Card>*/}
        {/*  <Card style={{width: '22rem'}} className="text-left">*/}
        {/*    <Card.Header className="text-center">*/}
        {/*      Done*/}
        {/*    </Card.Header>*/}
        {/*    <Card.Body>*/}
        {/*      <Card*/}
        {/*          bg={'success'}*/}
        {/*          text={'light'}*/}
        {/*          style={{ width: '18rem' }}*/}
        {/*      >*/}
        {/*        <Card.Header>Synonyms A*</Card.Header>*/}
        {/*        <Card.Body>*/}
        {/*          <Card.Title>Card Title </Card.Title>*/}
        {/*          <Card.Text>*/}
        {/*            Some quick example text to build on the card title and make up the*/}
        {/*            bulk of the card's content.*/}
        {/*          </Card.Text>*/}
        {/*        </Card.Body>*/}
        {/*      </Card>*/}

        {/*    </Card.Body>*/}
        {/*  </Card>*/}
        {/*</CardDeck>*/}
      </Row>
  );
};

export default Dashboard;