const qbdev = {
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_Iz1X6hubW",
    APP_CLIENT_ID: "2l5dav3dvv92qmfbuf3huqf7b1"
  },
  appsync: {
    GRAPHQL_ENDPOINT: 'https://ukqofjcbcveu3aepcf6gndzera.appsync-api.eu-west-2.amazonaws.com/graphql',
    REGION: 'eu-west-2',
    AUTH_TYPE: 'AMAZON_COGNITO_USER_POOLS',
  }

};

const dev = {
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_Lg7KWedhB",
    APP_CLIENT_ID: "7lq8nlgn603b7801ikk44e6ffs"
  },
  appsync: {
    GRAPHQL_ENDPOINT: 'https://poluset2obacrhq45gzsevdlvq.appsync-api.eu-west-2.amazonaws.com/graphql',
    REGION: 'eu-west-2',
    AUTH_TYPE: 'AMAZON_COGNITO_USER_POOLS',
  }

};

const stage = {
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_uNbAWQt2o",
    APP_CLIENT_ID: "7necgaolbf7jt25ln4oe0sapt1"
  },
  appsync: {
    GRAPHQL_ENDPOINT: 'https://vgfxjbvmlbcktbv7zp6o37yhfi.appsync-api.eu-west-2.amazonaws.com/graphql',
    REGION: 'eu-west-2',
    AUTH_TYPE: 'AMAZON_COGNITO_USER_POOLS',
  }

};

const prod = {
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "asdasd",
    APP_CLIENT_ID: "asdasd"
  }

};

let config;
switch (process.env.REACT_APP_STAGE) {
  case "prod":
    config = prod;
    break;
  case "stage":
    config = stage;
    break;
  case "dev":
    config = dev;
    break;
  default:
    config = qbdev;
}

export default {
  ...config
};