import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/home/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/login/Login";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Signup from "./containers/signup/Signup";
import PasswordReset from "./containers/passwordReset/PasswordReset";
import Profile from "./containers/profile/Profile";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

export default function Routes() {
  return (
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <UnauthenticatedRoute exact path="/login">
          <Login/>
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/signup">
          <Signup/>
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/reset">
          <PasswordReset/>
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/profile">
          <Profile/>
        </AuthenticatedRoute>
        {/* Finally, catch all unmatched routes */}
        <Route>
          <NotFound/>
        </Route>
      </Switch>
  );
}