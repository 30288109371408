
const initialValuesSignInForm = {
  userName: '',
  email: '',
  password: ''
}

const initialValuesSignUpForm = {
  ...initialValuesSignInForm,
  passwordConfirm: ''
}

const initialValuesCodeVerify = {
  code: ''
}

const initialValuesPasswordResetForm = {
  ...initialValuesSignUpForm
}

const getInitialValues = (viewMode) => {
  switch (viewMode) {
    case 'CodeVerification':
      return initialValuesCodeVerify;
    case 'Signup':
      return initialValuesSignUpForm;
    case 'PasswordReset':
      return initialValuesPasswordResetForm;
    default:
      return initialValuesSignInForm;
  }
}

export default getInitialValues;