import React, {useState} from "react";
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import {toast} from 'react-toastify';
import SignUserForm from "../../components/signUserForm/SignUserForm";
import {onError} from "../../libs/errorLib";

export default function PasswordReset() {
  const header = 'Password Reset';
  const history = useHistory();
  const [userConfirmed, setUserConfirmed] = useState(true);

  async function handleSubmit(values, helpers) {
    try {
      const username = `${values.email}-${values.userName}`

      await Auth.forgotPassword(username);
      setUserConfirmed(false);

      //helpers.setSubmitting(false);
    } catch (e) {
      onError(e);

      //helpers.setSubmitting(false);
    }
  }

  async function handleConfirmationSubmit(values, helpers) {
    try {
      const username = `${values.email}-${values.userName}`

      await Auth.forgotPasswordSubmit(username, values.code, values.password);
      toast.success("Your password has been reset. Please login again.")

      history.push("/login");
    } catch (e) {
      onError(e);

      helpers.setSubmitting(false);
    }
  }

  const handleCancel = () => {
    history.push("/login");
  }

  function confirmationForm() {
    return <SignUserForm viewMode={'CodeVerification'} onFormSubmit={handleConfirmationSubmit} header={header} onFormCancel={handleCancel}/>
  }

  function passwordResetForm() {
    return <SignUserForm viewMode={'PasswordReset'} onFormSubmit={handleSubmit} header={header} onFormCancel={handleCancel}/>
  }

  return (
      <div className="Login">
        {!userConfirmed ? confirmationForm() : passwordResetForm()}
      </div>
  );
}