import React, {useState} from "react";
import {Auth} from "aws-amplify";
import {useAppContext} from "../../libs/contextLib";
import {onError} from "../../libs/errorLib";
import "./Login.css";
import {useHistory} from "react-router-dom";
import {toast} from 'react-toastify';
import SignUserForm from "../../components/signUserForm/SignUserForm";

export default function Login() {
  const header = 'Signin';
  const history = useHistory();
  const {userHasAuthenticated} = useAppContext();
  const [userConfirmed, setUserConfirmed] = useState(true);
  const [userLoginValues, setUserLoginValues] = useState({});

  async function handleSubmit(values, helpers) {
    try {
      setUserLoginValues(values);

      const username = `${values.email}-${values.userName}`

      await Auth.signIn(username, values.password);
      userHasAuthenticated(true);

      //helpers.setSubmitting(false);
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        const username = `${values.email}-${values.userName}`
        await Auth.resendSignUp(username);

        toast.warn("Your email hasn't been verified yet.")
        setUserConfirmed(false);
      } else {
        onError(e);
      }

      //helpers.setSubmitting(false);
    }
  }

  const onResetPassword = () => {
    history.push("/reset");
  }

  async function handleConfirmationSubmit(values, helper) {
    try {
      const username = `${values.email}-${values.userName}`

      await Auth.confirmSignUp(username, values.code);
      toast.success("Your email has been verified.")

      await Auth.signIn(username, userLoginValues.password);
      userHasAuthenticated(true);

      history.push("/");
    } catch (e) {
      onError(e);

      //helpers.setSubmitting(false);
    }
  }

  const handleCancel = () => {
    history.push("/");
  }

  function confirmationForm() {
    return <SignUserForm viewMode={'CodeVerification'} onFormSubmit={handleConfirmationSubmit} header={header} onFormCancel={handleCancel}/>
  }

  function loginForm() {
    return <SignUserForm viewMode={'Signin'} onFormSubmit={handleSubmit} header={header} onFormCancel={handleCancel} onResetPassword={onResetPassword}/>
  }

  return (
      <div className="Login">
        {!userConfirmed ? confirmationForm() : loginForm()}
      </div>
  );
}