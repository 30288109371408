import getInitialValues from "./InitialValues";
import getSchema from "./ValidationSchema";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import React from "react";

const FormikForm = (props) => {
  return (
      <Formik
          initialValues={getInitialValues(props.viewMode)}
          validationSchema={getSchema(props.viewMode)}
          onSubmit={props.onFormSubmit}
      >
        {
          (formik) => {

            return (
                <Form>
                  {
                    (props.viewMode === 'Signin' || props.viewMode === 'Signup' || props.viewMode === 'PasswordReset' ) &&
                    <>
                      <div className="form-group" data-testid={"userNameFormGroup"}>
                        <label htmlFor="userName">User Name</label>
                        <Field data-testid={"userName"} name="userName" type="text" className="form-control"/>
                        <ErrorMessage name="userName"/>
                      </div>

                      <div className="form-group" data-testid={"emailFormGroup"}>
                        <label htmlFor="email">Email</label>
                        <Field data-testid={"email"} name="email" type="email" className="form-control"/>
                        <ErrorMessage name="email"/>
                      </div>

                      <div className="form-group" data-testid={"passwordFormGroup"}>
                        <label htmlFor="password">Password</label>
                        <Field data-testid={"password"} name="password" type="password" className="form-control"/>
                        <ErrorMessage name="password"/>
                      </div>
                    </>
                  }

                  {
                    ( props.viewMode === 'Signup' || props.viewMode === 'PasswordReset') &&
                    <div className="form-group" data-testid={"passwordConfirmFormGroup"}>
                      <label htmlFor="passwordConfirm">Confirm Password</label>
                      <Field data-testid={"passwordConfirm"} name="passwordConfirm" type="password" className="form-control"/>
                      <ErrorMessage name="passwordConfirm"/>
                    </div>
                  }

                  {
                    (props.viewMode === 'CodeVerification') &&
                    <div className="form-group" data-testid={"codeFormGroup"}>
                      <label htmlFor="code">Confirmation Code</label>
                      <Field data-testid={"code"} name="code" type="text" className="form-control"/>
                      <ErrorMessage name="code"/>
                    </div>
                  }

                  <button type="submit" className="btn btn-success" data-testid={"submit"} disabled={formik.isSubmitting}>
                    {
                      formik.isSubmitting ?
                          <>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            {' '}
                            Submitting...
                          </>
                          : "Submit"
                    }
                  </button>
                  {' '}
                  <Button type="button" data-testid={"cancel"} variant={"info"} onClick={props.onFormCancel}>
                    {"Cancel"}
                  </Button>
                  <br/>
                </Form>
            )
          }
        }
      </Formik>
  )
}

export default FormikForm;