import React from "react";
import "./Home.css";
import {useAppContext} from "../../libs/contextLib";
import GuestLander from "../guestLander/GuestLander";
import Dashboard from "../dashboard/Dashboard";

export default function Home() {
  const {isAuthenticated} = useAppContext();

  function renderLander() {
    return <GuestLander/>
  }

  function renderDashboard() {
    return <Dashboard/>
  }

  return (
      <>
        {isAuthenticated ? renderDashboard() : renderLander()}
      </>
  );
}