import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useAppContext} from "../../libs/contextLib";
import {onError} from "../../libs/errorLib";
import {callApiAndAwait} from "../../libs/graphqlLib";
import "./Signup.css";
import {Auth, API, graphqlOperation} from "aws-amplify";
import {toast} from "react-toastify";
import SignUserForm from "../../components/signUserForm/SignUserForm";
import {createUser} from "../../graphql/mutations";

export default function Signup() {
  const header = "Signup";
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const {userHasAuthenticated} = useAppContext();
  const [userLoginValues, setUserLoginValues] = useState({});

  async function handleSubmit(values, helpers) {
    try {
      setUserLoginValues(values);

      const username = `${values.email}-${values.userName}`

      const newUser = await Auth.signUp({
        username: username,
        password: values.password,
        attributes: {
          email: values.email,
          nickname: values.userName
        }
      });

      setNewUser(newUser);

      //helpers.setSubmitting(false);
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        toast.warn("Your email hasn't been verified yet.")
      } else {
        onError(e);
      }

      //helpers.setSubmitting(false);
    }
  }

  async function handleConfirmationSubmit(values, helpers) {
    try {
      const username = `${values.email}-${values.userName}`

      await Auth.confirmSignUp(username, values.code);
      toast.success("Your email has been verified.");

      await Auth.signIn(username, userLoginValues.password);

      const user = {
        username: username,
        email: values.email,
        nickName: values.userName
      };
      await callApiAndAwait( () => API.graphql(graphqlOperation(createUser, {...user})));

      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);

      //helper.setSubmitting(false);
    }
  }

  const handleCancel = () => {
    history.push("/");
  }

  function confirmationForm() {
    return <SignUserForm viewMode={'CodeVerification'} onFormSubmit={handleConfirmationSubmit} header={header} onFormCancel={handleCancel}/>
  }

  function renderForm() {
    return <SignUserForm viewMode={'Signup'} onFormSubmit={handleSubmit} header={header} onFormCancel={handleCancel}/>
  }

  return (
      <div className="Signup">
        {newUser === null ? renderForm() : confirmationForm()}
      </div>
  );
}