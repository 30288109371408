import React, {useState} from "react";
import {Card, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import FormikForm from "./FormikForm";
import Button from "react-bootstrap/Button";

const SignUserForm = (props) => {

  return (
      < Row className={"justify-content-md-center p-3"}>

        <Card style={{width: '22rem'}} className="text-left">

          <Card.Header className="text-center">
            {props.header}
          </Card.Header>

          <Card.Body>
            {
              (props.viewMode === 'Signup' || props.viewMode === 'PasswordReset' ) &&
              <Alert variant={"info"}>
                A verification code will be sent to your email address.
              </Alert>
            }

            {
              props.viewMode === 'CodeVerification' &&
              <Alert variant={"info"}>
                Please check your email for the code.
              </Alert>
            }

            <FormikForm {...props}/>

          </Card.Body>
          {
            props.viewMode === 'Signin' &&
            <Card.Footer>
              <Button type="button" size={'sm'} data-testid={"resetPassword"} variant={"outline-secondary"} onClick={props.onResetPassword}>
                {"Reset Password"}
              </Button>
            </Card.Footer>
          }
        </Card>
      </Row>
  );
};

export default SignUserForm;