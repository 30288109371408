import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {AiOutlineMail, BsFillPersonFill} from "react-icons/all";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {getUser} from "../../graphql/queries";
import Spinner from "react-bootstrap/Spinner";
import {callApiAndAwait} from "../../libs/graphqlLib";
import {onError} from "../../libs/errorLib";

const Profile = () => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {

    async function onLoad() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userInDynamo = await callApiAndAwait( () => API.graphql(graphqlOperation(getUser, {username: currentUser.username})));
        setProfile(userInDynamo.data.getUser);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);

  return (
      <Row className={"p-3"}>
        <Col>
          {
            profile ? <Card
                    bg={'light'}
                    text={'dark'}
                >
                  <Card.Header><h3>Profile</h3></Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <BsFillPersonFill/> {' '} {profile.nickName}
                    </Card.Text>
                    <Card.Text>
                      <AiOutlineMail/> {' '} {profile.email}
                    </Card.Text>
                  </Card.Body>
                </Card>
                :
                <> <Spinner animation="grow" variant="info"/>  Loading.. </>
          }
        </Col>
      </Row>
  );
};

export default Profile;