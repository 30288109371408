/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $username: String!
    $email: String!
    $currentCourse: String
    $nickName: String!
  ) {
    createUser(
      username: $username
      email: $email
      currentCourse: $currentCourse
      nickName: $nickName
    ) {
      username
      email
      currentCourse
      nickName
    }
  }
`;
