import {Link} from "react-router-dom";
import {Container, Jumbotron} from "react-bootstrap";
import React from "react";

const GuestLander = () => {
  return (
      <Jumbotron className={"rounded-0"}>
        <Container className={"text-center"}>
          <h1>Examic</h1>
          <p>
            This is a simple hero unit, a simple jumbotron-style component for calling
            extra attention to featured content or information.
          </p>
          <p>
            <Link to="/login" className="btn btn-success">
              Login
            </Link>
            {" "}
            <Link to="/signup" className="btn btn-success">
              Signup
            </Link>
          </p>
        </Container>
      </Jumbotron>
  );
}

export default GuestLander;