import React, {useEffect, useState} from "react";
import "./App.css";
import Routes from "./Routes";
import {AppContext} from "./libs/contextLib";
import {useHistory} from "react-router-dom";
import {onError} from "./libs/errorLib";
import {Auth} from "aws-amplify";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from "./containers/navigationBar/NavigationBar";

toast.configure();

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const history = useHistory();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/");
  }

  return (
      !isAuthenticating && (
          <Container fluid className="m-0 p-0">
            <Row>
              <Col>
                <NavigationBar isAuthenticated={isAuthenticated} handleLogout={handleLogout}/>
              </Col>
            </Row>
            <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
              <Routes/>
            </AppContext.Provider>
          </Container>
      )
  );
}

export default App;