import * as Yup from "yup";

const signInSpec = {
  userName: Yup.string()
      .max(10, 'Must be 10 characters or less')
      .min(3, 'Must be 3 characters or more')
      .required('Required'),
  email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
  password: Yup.string()
      .min(10, 'Must be 10 characters or more')
      .required('Required')
}

const signUpSpec = {
  passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  ...signInSpec
}

const passwordResetSpec = {
  ...signUpSpec
}

const verifyCodeSpec = {
  code: Yup.string()
      .required('Required')
}

const schema = (viewMode) => {
  switch (viewMode) {
    case 'CodeVerification':
      return verifyCodeSpec;
    case 'Signup':
      return signUpSpec;
    case 'PasswordReset':
      return passwordResetSpec;
    default:
      return signInSpec;
  }
};

const getSchema = (viewMode) => {
  return Yup.object(schema(viewMode))
}

export default getSchema;

